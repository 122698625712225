var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "xv-if": "LOGGED_IN && false"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "5",
      "offset-lg": "1"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text py-2 text-subtitle-2"
  }, [_vm._v("New Jobs - Past 7 Days")]), _c('v-card-text', {
    staticClass: "pa-4"
  }, [_c('v-sparkline', {
    staticClass: "px-1 sparklines",
    staticStyle: {
      "overflow": "visible"
    },
    attrs: {
      "value": _vm.incoming,
      "labels": _vm.incoming_labels,
      "smooth": 5,
      "line-width": 2,
      "padding": "18",
      "stroke-linecap": "round",
      "type": "trend",
      "auto-draw": "",
      "auto-draw-duration": 750
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "5"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text py-2 text-subtitle-2"
  }, [_vm._v("Due Jobs - Next 7 Days")]), _c('v-card-text', {
    staticClass: "pa-4"
  }, [_c('v-sparkline', {
    staticClass: "px-1 sparklines",
    staticStyle: {
      "overflow": "visible"
    },
    attrs: {
      "value": _vm.due,
      "labels": _vm.due_labels,
      "smooth": 5,
      "line-width": 2,
      "padding": "18",
      "stroke-linecap": "round",
      "type": "trend",
      "auto-draw": "",
      "auto-draw-duration": 750
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "5",
      "offset-lg": "1"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text py-2 text-subtitle-2"
  }, [_vm._v("Current Job Status")]), _c('v-card-text', {
    staticClass: "pa-4"
  }, [_c('v-simple-table', {
    attrs: {
      "dense": ""
    }
  }, [_c('tbody', _vm._l(_vm.status, function (s, k) {
    return _c('tr', {
      key: k
    }, [_c('td', [_c('span', {
      staticClass: "font-weight-medium"
    }, [_vm._v(_vm._s(s.name))])]), _c('td', [_c('div', {
      staticClass: "d-flex align-center",
      staticStyle: {
        "height": "20px"
      }
    }, [_c('div', {
      staticClass: "flex-shrink-0 text-right",
      staticStyle: {
        "width": "50px"
      }
    }, [_vm._v(" " + _vm._s(s.count) + " ")]), _c('v-progress-linear', {
      staticClass: "ml-2 align-center",
      attrs: {
        "value": s.count / _vm.status_max_count * 100,
        "xheight": "6",
        "rounded": "",
        "background-color": "transparent"
      }
    })], 1)])]);
  }), 0)])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "5"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text py-2 text-subtitle-2"
  }, [_vm._v("Current Manufacturing Step")]), _c('v-card-text', {
    staticClass: "pa-4"
  }, [_c('v-simple-table', {
    attrs: {
      "dense": ""
    }
  }, [_c('tbody', _vm._l(_vm.qms, function (s, k) {
    return _c('tr', {
      key: k
    }, [_c('td', [_c('span', {
      staticClass: "font-weight-medium"
    }, [_vm._v(_vm._s(s.name))])]), _c('td', [_c('div', {
      staticClass: "d-flex align-center",
      staticStyle: {
        "height": "20px"
      }
    }, [_c('div', {
      staticClass: "flex-shrink-0 text-right",
      staticStyle: {
        "width": "50px"
      }
    }, [_vm._v(" " + _vm._s(s.count) + " ")]), _c('v-progress-linear', {
      staticClass: "ml-2 align-center",
      attrs: {
        "value": s.count / _vm.qms_max_count * 100,
        "xheight": "6",
        "rounded": "",
        "background-color": "transparent"
      }
    })], 1)])]);
  }), 0)])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }